export const AllProjectItemSkeleton = () => (
  <div data-testid="project-skeleton" className="skeleton-template all-projects-skeleton">
    <div className="project-item project-item">
      <span className="project-item__link">
        <header className="project-item__header">
          <span className="project-item__media">
            <span className="skeleton__media vtmn-skeleton" />
          </span>
        </header>
      </span>
      <footer className="project-item__body">
        <div className="project-item__title vp-title-m">
          <div className="skeleton__item vtmn-skeleton" />
        </div>
      </footer>
    </div>
  </div>
);
