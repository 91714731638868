import type { ProjectType } from '~/model/ProjectType';

export type ProjectScrollerContentType = ProjectType | ProjectType[];

export enum ProjectItemContextEnum {
  cross_sell = 'cross-sell',
  staring = 'staring',
  followed = 'followed',
  top_projects = 'top-projects',
  homepageAll = 'homepage-all',
  all = 'all',
}
