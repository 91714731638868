import type { ReactNode } from 'react';
import { BeardmanProfile } from '~/components/Beardman/BeardmanProfile/BeardmanProfile';

export const Banner = ({
  children,
  description,
  title,
}: {
  children: ReactNode;
  description: string;
  title: string;
}) => {
  return (
    <div className="banner">
      <div className="banner__container">
        <div className="banner__container__picture">
          <BeardmanProfile />
        </div>
        <div className="banner__content">
          <div className="banner__content__title vp-title-s">{title}</div>
          <div className="banner__content__description vp-body-m">
            {description}
          </div>
          <div className="banner__content__actions">{children}</div>
        </div>
      </div>
    </div>
  );
};
