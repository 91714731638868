import type { PropsWithChildren } from 'react';
import { useTranslations } from 'next-intl';
import classNames from 'classnames';
import { Banner } from '~/components/commons/Banner/Banner';
import { MainColumn } from '~/components/commons/MainColumn/MainColumn';

export const CompleteProfileBannerWrapper = ({
  children,
  isHomepage,
}: PropsWithChildren<{ isHomepage?: boolean }>) => {
  const t = useTranslations();

  return (
    <MainColumn>
      <div
        data-testid="completeProfile"
        className={classNames('complete-profile complete-profile--banner', {
          'complete-profile--not-homepage': !isHomepage,
        })}
      >
        <Banner
          title={t('HOMEPAGE.COMPLETE_PROFILE.TITLE')}
          description={t('HOMEPAGE.COMPLETE_PROFILE.DESCRIPTION')}
        >
          {children}
        </Banner>
      </div>
    </MainColumn>
  );
};
