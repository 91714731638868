export const BeardmanProfile = () => {
  return (
    <svg width="210" height="210" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_970_6070)">
        <path d="M132.5 175c-6.3-8.2-12.4-17.8-11.5-28.1.2-1.7.5-3.5.1-5.1-.4-1.7-1.5-3.1-2.6-4.5l-5.2-6.3-28.7-35-.4.5c-2-1.8-5.3-.6-7.9.3a22 22 0 01-13.2.1c-3.6-1.1-7.5-4.3-6.5-8A87.8 87.8 0 0023 130.3a51 51 0 00-2.7 8.9 36 36 0 001.1 17.6c1.3 4 3.3 7.8 6.6 10.3 6.1 4.5 14.5 5 22 3.7.3 4.8 1 9.6 2.2 14.3 4.7-4.5 12.4-6.1 19.4-5.2l2.6.5c4.2-.4 8.5 0 12.6 1.1.5.1.5.7.3 1.2l2.6.6 8.7 1a119.3 119.3 0 0024.6-.2 60 60 0 0012.5-2.7c-.3-2.2-1.8-4.5-3.1-6.3z" fill="#7A83D1" />
        <path d="M61.7 178v-.2h-.1l-.2-.2a.4.4 0 00-.4.1v.7h.6l.1-.2v-.1z" fill="#ABB2EB" />
        <path d="M97 182.7c-3.5.9-7 .4-10.4-.4l3.1.4c.4 0 .5-.7.1-.8l-1-.1h.4a.4.4 0 00.3-.6l-.2-.1a42 42 0 00-15.4-1c-3.1-.4-6.3-.8-9.6-1a26.9 26.9 0 00-7 .8.4.4 0 000 .5h.3l1.9.1a.4.4 0 00.3.2l1-.2 12.8.5 3.5.2 2.3.3c.7 0 1.4.2 2 .3 2.5.5 5 1.2 7.5 1.6 2.7.6 5.5.6 8.2 0a.4.4 0 000-.6H97zm-13.4-2l-3.1-.1a.3.3 0 00-.3.1l-2-.2c1.8 0 3.6 0 5.4.3zM121.6 143.1a1 1 0 00-.3-.3 1 1 0 00-.7-1.2 1 1 0 00-.5-.3l-5.2-1.4h-.3v-.2l-.3-1.5c.2-.2.5-.4.6-.7.3-.4.4-.9.4-1.4l-.3-1a2.6 2.6 0 00-1-1c-.2-1.3-.7-2.6-1.3-3.8a1 1 0 00-1.9 1.1c.6 1 1 2 1.1 3v.4l.1.6.1 1.2-.2.1a1 1 0 00-.5.7 1 1 0 00.7 1.3v.4l.6 1.9v.7l-.1.2-1.3.5-2 .4-5 1a1 1 0 00-.6 1.3l.2.4a1.5 1.5 0 000 1.4l.2.6.2.2.3.6.2.5a1 1 0 00-1 .3c-4.6 5.1-11 8.2-17.3 10.8a198.8 198.8 0 01-35 10.8 1 1 0 00-.7 1.3 1 1 0 001.3.7 200.1 200.1 0 0043.9-15 37.3 37.3 0 009.5-7.3h.5a1 1 0 001-1 5.7 5.7 0 00-.7-2.6l4-.7c.4.2.9.4 1.4.4l4 .4c1.4 0 2.7.1 3.8-.7a3 3 0 001-1.3c.3 0 .5-.2.7-.4a1.1 1.1 0 00.4-1.4zM54.8 125.1c0 1.7-.8 3.2-1.4 4.7l-2 4.5c-1.6 3-3.7 5.5-5.2 8.5a1 1 0 101.9 1c1.5-3 3.7-5.6 5.2-8.7.8-1.6 1.5-3.2 2.1-4.9.7-1.6 1.5-3.3 1.5-5 0-1.5-2.2-1.5-2.1 0zM86.2 96a1 1 0 00-1.4-.4 32 32 0 01-12.6 2c-4 .1-8.6.5-12.1-1.8-1.7-1.1-3-2.7-3.9-4.6a1 1 0 00-1.4-.4 1 1 0 00-.4 1.5c.8 1.9 2.2 3.5 3.9 4.8a13 13 0 005.9 2.4 64.4 64.4 0 0014 0c2.6-.3 5.2-1 7.6-2a1 1 0 00.4-1.5z" fill="#3643BA" />
        <path d="M55 62.7v-.2l-.3-.3h.1a1 1 0 00-.4-1.5 2.3 2.3 0 00-2-.2l-1 .6c-.3.3-.5.7-.6 1v1.2a2.4 2.4 0 001.5 1.6c.1.2.3.3.5.3h.5l.5-.1.6-.2.5-.5.1-.7.1-.6v-.4z" fill="#fff" />
        <path d="M55 125c-1.6 5.1-5 9.4-7.4 14.2-.7 1.3-1.2 2.7-1.7 4.1-.1.5.6.6.7.2 1.6-5.1 5-9.4 7.4-14.2l1.7-4.1c.1-.4-.6-.6-.7-.2z" fill="#24286B" />
        <path d="M45.5 144.8a320.6 320.6 0 0050-14.4c.5-.2.3-.8-.1-.7A327 327 0 0145.3 144c-.5 0-.3.8.2.7z" fill="#24286B" />
        <path d="M95 129.7c.8 2.7 2.4 5.2 4 7.6a71.2 71.2 0 015.9 12.9c.1.4.8.2.7-.2a72 72 0 00-3.5-8.3c-1.3-2.6-2.8-5.2-4.3-7.7-.9-1.4-1.6-3-2.2-4.5-.1-.4-.8-.2-.7.2z" fill="#24286B" />
        <path d="M56 89c-4.7 3.2-9.1 7-13.3 11-4 4-7.8 8.3-11 13a74 74 0 00-8 15.3c-2.2 5.4-3.5 11-4 16.8-.5 5.2 0 10.8 2.4 15.5 2.4 4.8 6.7 8 11.6 9.6a35.9 35.9 0 0017.5.7 115 115 0 0017.8-4.5c5.6-1.7 11.1-3.7 16.5-6a110.7 110.7 0 0019-10.8c.4-.2 0-.9-.3-.6-4.7 3.3-9.7 6.3-14.8 8.8-5.2 2.5-10.6 4.7-16 6.5-5.6 1.8-11.2 3.4-16.8 4.7-6 1.5-12.3 2.6-18.5 1.6-5-.9-10-3-13.3-7-3.4-4.1-4.6-9.6-4.6-14.8a55 55 0 012.8-16.5 70.6 70.6 0 017-15.6 101.7 101.7 0 0126.2-27.2c.5-.2.1-.8-.2-.6zM55.2 60.7a3.9 3.9 0 00-4.5 1 2.4 2.4 0 00-.5 2.4 3 3 0 001.8 1.6c1.1.3 2.4.3 3.5-.1.4-.1.3-.8-.2-.7-1.4.3-3.4.7-4.3-.7-.8-1.3.5-2.7 1.7-3a3 3 0 012.1 0 .4.4 0 00.5 0 .4.4 0 00-.1-.6z" fill="#24286B" />
        <path d="M135.7 143.2a49.7 49.7 0 100-99.5 49.7 49.7 0 000 99.5z" fill="#F1F0EE" stroke="#24286B" strokeWidth=".7" strokeMiterlimit="10" />
        <path d="M85 41.5a1 1 0 00-1.3-.7 1 1 0 00-.4.2 1 1 0 00-.5 0l-.2.1a1 1 0 00-1-.8 1.1 1.1 0 00-1 .6 1 1 0 00-1 .5l-.2.3a1 1 0 00-1 .1 1 1 0 00-1.2.3v-.6a1 1 0 10-2 0l-.1-.2a1 1 0 00-2-.2 1 1 0 00-.5.2l.2-.4a1 1 0 00-.7-1.2 1 1 0 00-1.3.4l-1.2 2 .2-.5c.3-.9-.8-1.6-1.6-1.2-.1-.2-.3-.3-.5-.3a1 1 0 00-1.1.4 1 1 0 00-1.8-.6c-2.2 1.7-4 3.8-5.3 6.2a1.1 1.1 0 000 1l-1.7 1.8a1.1 1.1 0 000 1.5 1 1 0 001.5 0l.6-.5v.4c.1 2-.2 4.2-.8 6.2l-.7 1.5a2.4 2.4 0 00-.4 2.3c.4.5.8 1 1.4 1.4l.6.5a1 1 0 001 .8l.2.1c.1.2.3.3.6.3l.5.3a1 1 0 001.2.8l.6-.3c0 .6.2 1.2.4 1.8a1 1 0 002-.3v-.1c.4.8 2 1 2.1-.1a120.6 120.6 0 01.6-2.6l.3 1a1 1 0 001.2.8l.6-.3a1 1 0 00.8-.8 1 1 0 001.3.8 1 1 0 001-1l-.1-.7h1v.2a1 1 0 102-.6v-.1a1 1 0 001.3 0l.3-.6H79.8l.4-.2.8-.1A1 1 0 0083 61a2.1 2.1 0 001.3-2.2 1.4 1.4 0 001 .4 1.9 1.9 0 001.5-.6v1.2a1 1 0 001 1 1 1 0 001-1c.6-6.4-2.8-12.1-3.7-18.3z" fill="#fff" />
        <path d="M81.3 95c-.6-1.6-1.6-4.7.2-5.7-1 .5-1 1.7-1 2.8l.7 2.7s0 .2 0 0v-.5V93c0-.8.2-2.2 1-2.4l.1-1a1.2 1.2 0 00-.7-1.4 1 1 0 00-1.1.6c-.1-.2-.3-.3-.5-.3l-1.8-.2a18.7 18.7 0 01-3.7-.7l-1.1-.9a26.7 26.7 0 01-6.8-8.7.8.8 0 00-1 0l-.4-.6-.6-1.8-1.5-4.8-1.5-4.5a9.5 9.5 0 00-2.2-3.5v-.5.1l-.1-.8a1 1 0 00-.6-.5.9.9 0 00-.7 0 1 1 0 00-.5.8c-.2 1.2-.2 2.4 0 3.5 0 .3.2.6.5.8-.2 1.2-.2 2.4-.2 3.7a1 1 0 00-1.1 1.4 38.4 38.4 0 01-.5 20.2c1.1 3.8 4.4 5.8 7.6 6.6A18.3 18.3 0 0075 98c1.8-.6 3.5-1 5.3-1.3a.8.8 0 00.6.2 1 1 0 001-1.2v-.2-.2-2.7V91.7l.1-.5.1-.5c-1 .3-.8 3.4-.8 4.3z" fill="#fff" />
        <path d="M88.1 55l.1-.3a.3.3 0 000-.3c.1-.3.2-.7.1-1.1a1.6 1.6 0 00-1.3-1.4h-.2a.3.3 0 00-.3-.3 7.8 7.8 0 01-7-7.1c0-.5-.7-.5-.7 0l.1 1c-.5.9 0 1.9.5 2.7a11.2 11.2 0 007 4.6.4.4 0 00.4-.2 1 1 0 01.8 1c0 .4-.1.6-.3.9-.9.5-1.7 1-2.4 1.8l-.1.1-1.2.6h-1.4c-.4 0-.4.6 0 .7h.1c0 .2.2.5.5.4l.2-.1a.3.3 0 00.4 0H84l.3-.2a.3.3 0 00.3 0l.1-.1 1-.5a7 7 0 002.5-1.9.3.3 0 000-.4zM81.5 89.7a.4.4 0 00-.6-.3.3.3 0 00-.2-.4c-3.4-.5-6.6-1.8-9.4-3.8l-1-1a60.1 60.1 0 01-7.4-8.4l-.7-1.2a38.4 38.4 0 01-1.5-3.7.4.4 0 000-.4 9 9 0 01-.7-.8 36.5 36.5 0 01-.8-2 .4.4 0 00-.3 0l-.3-.6c-.1-.4-.8-.2-.7.2a.3.3 0 00-.2.4v.1l.5 1.2.2 1a10.7 10.7 0 01-.4 6.2l.1.1a.4.4 0 00.6 0c.5-.9.8-1.9 1-3A37 37 0 0066 84a27 27 0 002.4 2.7.3.3 0 00.2-.1c1.1 1 2.3 1.8 3.5 2.6 1.7 1.1 3.5 1.9 5.5 2.3l1.4.9.5.4a.3.3 0 00.4 0 .3.3 0 00.4-.1.4.4 0 00.2-.5l-.2-.4v-.3a.4.4 0 00.2-.7v-.4c.5 0 1-.2.9-.7zm-1 0a.4.4 0 00.3 0h-.2a2 2 0 01-.4 0l-.8-.2a22.3 22.3 0 01-4-1.2l-.3-.1c1.7.7 3.6 1.2 5.4 1.5z" fill="#D8DBF5" />
        <path d="M84.5 42.3a1.8 1.8 0 00-1.7-.3l-2.9.4a6.1 6.1 0 01-4.7-1.6.4.4 0 00-.4.1l-.2-.1c-.4-.2-.7.4-.4.6l-4 .8c-1.1 0-2.4-.3-3.5-.8l-.1-.2a.4.4 0 00-.3-.6h-.2l-.3-.3a.4.4 0 00-.6.2.3.3 0 00-.3.4 26.9 26.9 0 00-5.5 6.1v1c.1.8.4 1.5.7 2.2.6 1.5.9 3 .7 4.6a5.1 5.1 0 01-2 3.6.3.3 0 00-.2.2.4.4 0 000 .4v.1c.7 1 1.6 1.8 2.6 2.4l.5.4c.3.2.7-.3.4-.6l-.6-.6a.4.4 0 000-.5l-2-1.5c1.8-1.4 2.3-4 2-6.2-.1-.8-.3-1.5-.6-2.2l-.8-2.2c-.3-.8.2-1.5.7-2l1.5-1.9 1.8-1.7a6 6 0 011-.8c.2.3.5.6.9.8a8 8 0 003.5 1.6c1.5.4 3.3.4 4.5-.6l.1-.1.7-.6a.9.9 0 011.1 0c1.2.6 2.5 1 3.8 1.3 1.4.3 2.9.1 4.1-.5a.3.3 0 00.2-.5l.5-.2a.4.4 0 000-.6zm-3 .6l1.4-.2h.5c-.9.3-1.8.4-2.7.3h.9z" fill="#D8DBF5" />
        <path d="M78.1 44.5c-.8 2.5.7 5 2.7 6.5 1.2.8 2.4 1.5 3.7 2 .6.3 1.2.5 1.9.5.3 0 .8 0 .5.4l-.8.7c-1.3 1-2.8 1.9-4.4 2a.4.4 0 000 .6c1.4 0 2.8-.6 4-1.4.6-.5 3-1.8 1.7-2.8-.5-.4-1.2-.2-1.8-.3a16.5 16.5 0 01-5.7-3.5 4.9 4.9 0 01-1-4.5c0-.4-.6-.6-.8-.2zM52.5 63.5v-.1h.1s0-.1 0 0l.1-.2h.5c0-.1 0 0 0 0s.1 0 0 0c0 0 .1 0 0 0 0 0 .1 0 0 0l.1.6.6-.7a.4.4 0 000-.5.4.4 0 00-.5 0l-.6.7a.4.4 0 000 .4l.1.1a.8.8 0 00.9 0 .8.8 0 00.3-.9 1 1 0 00-.6-.6 1 1 0 00-.9 0l-.7.7a.4.4 0 00.2.5.4.4 0 00.5-.1v.1zM56.8 73.5a29 29 0 011 12.8c-.3 1-.7 2.2-1.7 2.5a.4.4 0 00.1.7 3.2 3.2 0 002-2.4c.4-1.4.6-2.8.6-4.2 0-3.3-.4-6.5-1.3-9.6-.2-.5-.9-.3-.8.2zM81.6 89.8a5.4 5.4 0 001.7 7c.4.2.7-.4.3-.6a4.7 4.7 0 01-1.4-6 .4.4 0 00-.6-.4z" fill="#24286B" />
        <path d="M175.2 126l-.2-.2a16.5 16.5 0 00-7.9-2.2c-.6 0-1.2.2-1.6.6h-.2a1 1 0 00-1 .8 1 1 0 00.5 1.2l.3.1.4.4c.5.3 1 .5 1.5.5h.2l1.8.5c-1.7 0-3.5 0-5.2.2-.8 0-1.8 0-2.2.8a1.5 1.5 0 00.6 2.2c.8.3 1.5.5 2.3.5l1 .2-2.4.4h-.8a8 8 0 00-3.5.8 1.7 1.7 0 00-1 1.5 1.6 1.6 0 001 1.3l.5.4c3.2.7 6.7 1.4 9.8.2 1.5-.7 2.9-1.6 4-2.9 1-1 2.3-2.3 1.8-3.7l.2-.6v-.2l.3-.2a1.6 1.6 0 00.8-1.3 1.7 1.7 0 00-1-1.4z" fill="#fff" />
        <path d="M175.4 129a2.5 2.5 0 001.6-2.5 1.8 1.8 0 00-2.3-1.4.4.4 0 00-.2.4.4.4 0 00.3.3h.1a.9.9 0 01.9 0 1.2 1.2 0 01.5.9 1.9 1.9 0 01-1.2 1.7l-.2.2v.3l.3.2h.2z" fill="#24286B" />
        <path d="M175.6 125.2a17 17 0 00-9-1.8c-1 0-3 .2-2.9 1.6a2.2 2.2 0 001.4 1.7c1.3.7 2.7 1.2 4.1 1.5.5 0 .6-.6.2-.7a13.6 13.6 0 01-4.6-1.9c-.2-.2-.5-.6-.3-1a1 1 0 01.6-.3 16 16 0 0110.2 1.5c.4.2.8-.4.4-.6z" fill="#24286B" />
        <path d="M167.6 127.5h-2.5c-.7 0-1.4 0-2.2.2-1 .2-2.2 1.1-2 2.4.3 1.1 1.5 1.7 2.4 2 1.5.5 3.1.7 4.7.8v-.8c-2.7 0-5.5-1-8.1-.2-1.2.3-2.2 1-2.8 2-.6 1 .1 2 1 2.4a17 17 0 008 1.5c2.4 0 4.8-1 6.7-2.6a8.1 8.1 0 002.9-6.8c0-.4-.8-.4-.7 0a7.3 7.3 0 01-2 5.6 9.6 9.6 0 01-5.6 3c-2.4.3-5 0-7.3-.7-.8-.2-2.4-.6-2.5-1.6 0-.5.5-1 1-1.4l1.3-.7c2.7-.8 5.4.3 8 .2h.3l.1-.3a.4.4 0 00-.3-.4c-1.3 0-2.7-.2-4-.5-.8-.2-2-.6-2.3-1.5-.5-1.3 1.2-1.8 2-1.8l3.9-.2a.4.4 0 00.3-.3.4.4 0 00-.3-.4zM98.6 78.1l-.4-1.7c-.5-1-1.1-2-2-2.9-1.6-1.7-3.8-2.9-5.3-4.7-.9-.8-1.4-2-1.6-3.1-.1-1.8.4-3.5 0-5.2l-.2-.8a5 5 0 00-.2-.4c-.2-.3-.4-.7-.4-1h-.2l-.9.1h-4.3c-1-.2-2-.3-3-.1-1 .2-1.8.6-2.7 1a27.2 27.2 0 01-4.4 1.4l-3 .4c-1.8.3-3.4.5-5 .9h-.2a15 15 0 00-1.8-.1c-.5 0-1 0-1.4.3l-.2-.1-2-2-1-1-.2-.3a6 6 0 002.5-4.3c.3-2.4-1-4.6-1.3-6.9 1.2-1.3 2.2-2.6 3-4.1l1.5-2c.4-.4 1-.8 1.5-1 1.2 1.7 3.6 2.4 5.6 2.2 1.6 0 3.2-.7 4.4-1.8l.7.5c1.3.6 2.6 1 4 1 2.5.3 5-.4 7.2-2A4.5 4.5 0 0089 37a3.1 3.1 0 00.6-2.7 3 3 0 00-.8-1.4c-.9-.8-2-.8-3.2-.8-1 0-1.8-.2-2.7-.5-2-.7-3.8-1.7-5.8-2.3-2.2-.6-4.5-.5-6.7.4-1.7.6-3.2 1.9-4 3.6a7 7 0 00-7 1 8.9 8.9 0 00-3.2 6.5c-2.2.4-4 2.3-4.8 4.4-1 3-.3 6.5.3 9.5.3 1.8.8 3.6 1.6 5.2 0 .2.2.3.3.4.6 1.8.9 3.8 1 5.8a38 38 0 001.2 8.4l.4.8a1.2 1.2 0 001.3.5c1.3-.4 1.3-2.1 1.3-3.2 0-2 0-3.8-.3-5.8l4 8c1.5 3 3.3 6.2 6 8.4l.4.3c2 2.2 4.6 3.8 7.5 4.7A35 35 0 0087 90.1c3.3 0 6.8-.9 9.2-3.3a8.3 8.3 0 002.4-6V78z" fill="#24286B" />
        <path d="M84.5 62.7c-4.2 3.2-9.8 2.5-14.7 1.8-.3 0-.6.2-.5.4a8.4 8.4 0 005.8 4.7c3.1.9 6.5.2 9.6-.5.4-.1.2-.8-.2-.7-3 .7-6 1.3-9 .6-2.3-.6-4.6-2-5.5-4.3l-.4.4c5 .8 10.9 1.5 15.2-1.9.4-.2 0-.9-.3-.6z" fill="#fff" />
        <path d="M71 65.4c-.3.6 0 1.3.7 1.1a.8.8 0 00.3-1.4.4.4 0 00-.4 0l-.9.3a.4.4 0 000 .6l1.7 1c.3 0 .9 0 1-.3.3-.4-.2-.9-.6-1-.4-.3-1-.4-1.5-.2a.4.4 0 00-.2.5l.3.4c.1.3.7.2.6-.1a1.2 1.2 0 00-.6-1l-.4.6c.7.4 1.5.7 2.3.8a.4.4 0 00.4-.6 1 1 0 00-1-.4.4.4 0 00-.3.5l.1.2c2 1 4.2.7 6.3.3 1.8-.3 4-.6 5.2-2.2a.4.4 0 00-.3-.5c-.7.1-1.5.3-2.2.6a22.9 22.9 0 01-7.8 1.2.4.4 0 00.1.6c2 .6 4.1.5 6.1-.1 1-.3 2-.6 2.8-1a4 4 0 002-1.3l-.7-.2c.2 1.4 0 2.8-.3 4.2h.7l.4-4.6H84a11 11 0 01-.2 4.3.4.4 0 10.7.1c.5-1.4.7-3 .5-4.5l-.7.1c.2 1.4.2 2.8-.2 4.1-.1.3-.3 1 .2 1 .5.2.6-.3.5-.6a.4.4 0 00-.4-.2.4.4 0 00.1.7l-.3-.1V68l.3-.2h-.1.3l.1-.3.2-.7a9.9 9.9 0 000-3.6c-.1-.4-.7-.3-.7 0 .1 1.5 0 3-.5 4.4l.7.2c.4-1.5.5-3 .3-4.5a.4.4 0 10-.8 0l-.3 4.7c0 .4.5.4.7 0a11.2 11.2 0 00.3-4.5.4.4 0 00-.6 0c-.5.6-1.2 1-2 1.2l-2.5.8c-1.8.6-3.7.6-5.6.1v.7a24.3 24.3 0 009.7-1.7l-.3-.5c-.5.6-1.2 1-2 1.2l-2.8.7c-1.9.3-4 .7-5.7-.3l-.2.7a.4.4 0 01.5.2l.3-.6a7 7 0 01-2.1-.8c-.4-.2-.8.4-.4.6a.5.5 0 01.3.4l.7-.2-.3-.5-.2.6a1.4 1.4 0 011.4.4v-.2l-.3-.1-.5-.3-1-.6-.2.6c.4 0 .7-.1 1-.3h-.4c.3-.5-.4-.7-.6-.3z" fill="#fff" />
        <path d="M70.2 65.6c1.4.4 2.8.7 4.3.7a.4.4 0 000-.7c-1.4 0-2.8-.3-4.1-.7-.5-.2-.7.5-.2.7z" fill="#fff" />
        <path d="M70.8 66a140.4 140.4 0 007.4 2.2.4.4 0 000-.6l-.4-.2c-.4-.2-.7.4-.3.6l.4.2.3-.6-7.2-2.3c-.5-.2-.7.5-.2.7z" fill="#fff" />
        <path d="M70.9 66.1a2.5 2.5 0 001.7 1.7c1.1.4 2.3.8 3.4 1 .5.2.6-.4.2-.6l-4-1.7-.2.7c1.7.6 3.5 1 5.2 1.2.4 0 .5-.5.2-.7-1-.4-2.2-.7-3.4-.8a.4.4 0 00-.2.6c1.3.6 2.6 1 4 1.2.4 0 .6-.6.1-.7a23.7 23.7 0 00-4-.4.4.4 0 000 .6c.7.6 1.6.8 2.5.7a.4.4 0 000-.7L74 68c-.4 0-.4.6-.1.8a9.5 9.5 0 006.5.2.4.4 0 00-.2-.6 14 14 0 00-4.3-.5v.7c1.7 0 3.4-.3 5-.8.3 0 .4-.6 0-.7-1.5-.2-3-.3-4.5-.1-.4 0-.5.7 0 .7h2.9c.6 0 1.4 0 2-.5a.4.4 0 00-.3-.6c-1.3-.2-2.6-.2-4 0-.3.1-.2.7.2.7L82 67c.4 0 .5-.6.1-.7l-1.5-.3-.1.8h2.2V66l-2.5.5-2.5.7a16.7 16.7 0 01-5.2-.4c-.4-.1-.6.5-.3.7.8.4 1.8.8 2.7 1l3.2.5c1.7.3 4 .8 5.1-1a.3.3 0 000-.4 1.9 1.9 0 00-2.3-.2.4.4 0 00-.2.5 1.4 1.4 0 001.8.6.4.4 0 00.2-.4 1 1 0 01.7-1.3l-.5-.5-.3 1.5v.3c0 .2 0 0 0 0l-.7-.2a10 10 0 00-2.6-.6c-.4 0-.5.5-.2.7 1.3.9 3.4 1 4.2-.7.2-.5.3-1.5-.3-2-.5-.4-1.6 0-2 .3-.3.2-.2.5 0 .6a2.1 2.1 0 003-.5l-.7-.1c.2.9 0 1.8-.3 2.6l.6.2c.3-.7.4-1.6.3-2.4 0-.5-.8-.5-.7 0a5 5 0 01-.3 2.3c-.1.3.5.6.7.2.5-1 .6-2 .4-3.1 0-.3-.5-.3-.6-.1a1.4 1.4 0 01-2 .3v.5a1.3 1.3 0 011.4-.1l-.3-.2a1.3 1.3 0 01-.7 2 2.5 2.5 0 01-2.4-.4l-.1.6 1.7.3 1.5.6a.6.6 0 00.7-.5l.5-2a.4.4 0 00-.5-.4 1.7 1.7 0 00-1.1 2.3l.1-.4a.6.6 0 01-.8-.4l-.1.5a1.2 1.2 0 011.4.1v-.4a2.3 2.3 0 01-2.1.9c-1 0-2-.2-3-.4-1.6-.3-3.5-.4-5-1.2l-.3.6c1.7.5 3.5.7 5.2.4.9 0 1.7-.3 2.5-.6 1-.3 1.8-.5 2.8-.6a.4.4 0 000-.7h-2.3c-.4 0-.5.7 0 .7l1.5.3v-.7c-1.5.2-3.2.3-4.8.3l.1.7a14 14 0 013.7 0l-.2-.6a1 1 0 01-.9.2h-3.5v.8a14 14 0 014.3.1v-.7c-1.5.4-3.1.7-4.8.7a.4.4 0 000 .7c1.4 0 2.8.1 4.1.5v-.8c-2 .6-4 .5-5.9-.2v.7l2.4.2v-.7c-.8 0-1.6-.2-2.3-.7l-.1.7c1.2 0 2.4.1 3.6.4l.2-.7c-1.3-.2-2.5-.6-3.7-1l-.2.6c1 0 2.1.3 3 .7l.3-.6c-1.7-.2-3.4-.6-5-1.2-.5-.2-.6.5-.2.7l4 1.7.1-.7-2.8-.9c-.7-.2-1.6-.5-1.8-1.3-.1-.5-.8-.3-.7.1z" fill="#fff" />
        <path d="M83.5 65l-.2.2h-.1-.1v.4l-.2.7a.4.4 0 00.7.2l.6-1.2-.6-.3c-.3.5-.4 1.2-.5 1.8a.4.4 0 00.4.4.4.4 0 00.4-.4v-.4-.4l.1-.3v-.2s.1-.1 0-.1a.4.4 0 000-.6.4.4 0 00-.5.2l-.6 1.1.6.2v-.6l.2-.5a.6.6 0 00-.3-.5.4.4 0 00-.6.3.4.4 0 10.7 0z" fill="#fff" />
        <path d="M84.2 63.3a13 13 0 01-.5 4.9h.7l.4-3.6a.4.4 0 00-.4-.3.4.4 0 00-.4.3l-.4 3.7c0 .4.6.4.7 0 .6-1.6.8-3.3.7-5 0-.4-.8-.4-.8 0z" fill="#fff" />
        <path d="M84 63.4a13.7 13.7 0 010 4.2l-.1.7c0 .3 0 .4.2.6a.4.4 0 00.7-.1 15.3 15.3 0 00.1-5.6h-.2c-.2 0-.4 0-.4.3a15 15 0 01-.3 5.1h.7l-.2-.4v.3c.4-1.7.4-3.5.1-5.2 0-.5-.7-.3-.7.1h.1zM65 67a1.7 1.7 0 011.9-2.2.4.4 0 00.4-.3.4.4 0 00-.2-.4 2.4 2.4 0 00-2.8 3.3.4.4 0 10.6-.3z" fill="#fff" />
        <path d="M67.3 38.3a4.2 4.2 0 005 2.5.4.4 0 00-.3-.7 3.5 3.5 0 01-4-2 .4.4 0 00-.5-.3.4.4 0 00-.2.5z" fill="#24286B" />
        <path d="M67 38.3a4 4 0 002.4 2c1.3.4 2.5-.1 3.7-.6.4-.2 0-.8-.4-.7-1 .5-2 1-3 .6a3.2 3.2 0 01-2.1-1.7c-.2-.4-.8 0-.6.4z" fill="#24286B" />
        <path d="M66.4 38.4c.2 1.1 1.4 1.6 2.3 1.8 1.2.2 2.4 0 3.5-.3.5-.2.3-.9-.2-.7-.9.3-1.9.4-2.9.3-.7-.1-1.8-.4-2-1.3 0-.4-.8-.2-.7.2zM69.6 32.5c1.9-2.3 5.2-1.7 7.5-.3l1.8 1.2a6 6 0 001.9.5c1.4.1 2.9 0 4.3-.3.4 0 .2-.8-.2-.7-1.5.4-3 .4-4.5.2-1.5-.3-2.6-1.5-4-2.1-2.4-1.1-5.5-1.2-7.3 1-.2.3.3.8.5.5zM57.3 49.3c.6 2 1.5 5-.6 6.6-.4.3 0 .9.3.6a4.4 4.4 0 001.7-3c0-1.6-.2-3-.7-4.4-.2-.5-.8-.3-.7.2zM117.6 115a28.8 28.8 0 00-9 5.9c-.1.1-.3.3-.3.5l-2 1.5-.6.4a.3.3 0 00-.4 0l-5.4 3.5a.4.4 0 00-.1.4l.1.1-.8.8a.3.3 0 00-.1.3l-.6.4-1.5.9-.7.4a.4.4 0 00.2.6 1 1 0 000 .9 77.2 77.2 0 016.6 12.2c.2.1.5.1.8 0l4-1 1.8-.7.6-.2 1.3-.5.7-.7c.2-.4.4-.9.4-1.3l.2-1.1-.1-.5-.1-.4-.2-.3-.4-.2.8-1 .3-.4a1 1 0 00-.2-2.1l-.7.1-.6-.9.3-.8c.4-.4.7-1 .8-1.5a3 3 0 00-.2-2.3 2.5 2.5 0 00-2.7-1.2h-.3l.2-.2a130.1 130.1 0 007.3-6 8.1 8.1 0 002-4.5 1 1 0 00-1.4-1zM105 132.8v-.4l.1-.5.2.9a1 1 0 00-.3 0z" fill="#fff" />
        <path d="M95.9 130.7l14.8-11.2c2.2-1.6 4.4-3.3 7-3.9.3 0 .6-.1.8 0 .4 0 .4.5.4.9 0 .6 0 1.2-.3 1.7a8.2 8.2 0 01-2.1 3.2c-2 2-4.9 3.2-6.7 5.5a.4.4 0 00.5.5c1-.9 2.5.5 2.7 1.6.2 1.6-1.2 3-2.4 3.8-.3.3-.1.8.3.7a2 2 0 012.5 1 1.9 1.9 0 01-1.7 2.4c-.3 0-.5.5-.2.7a2.3 2.3 0 011.4 2.2c0 1-1 1.7-2 2.1l-3.6 1-4.2 1a.4.4 0 00.2.8l4.8-1.3c1.4-.4 2.9-.6 4-1.3a2.8 2.8 0 001.5-2.7 2.9 2.9 0 00-1.7-2.4l-.2.6a2.5 2.5 0 10-1-4.8l.2.6c1.5-1 3.4-3 2.7-5-.5-1.4-2.4-2.6-3.9-1.5l.5.5c1.9-2.2 4.5-3.4 6.5-5.3 1-.8 1.8-2 2.4-3.1a6 6 0 00.5-1.8c0-.6 0-1.2-.2-1.8-.6-1.1-2.5-.3-3.4 0-1.3.5-2.6 1.2-3.7 2-2.7 1.9-5.2 4-7.8 5.9l-9 6.8c-.3.2 0 .9.4.6z" fill="#24286B" />
        <path d="M56.8 137.8l3.1-5 1.6-2.4c.2-.3.4-1 .8-1 .3-.2.7.2 1 .5.5.7 1 1.5 1.4 2.4l1.4 2a1.2 1.2 0 001.6.2l.9-.8 1-1.2 4.4-4.8h-.4a145.7 145.7 0 009.3 4.5c2-2.5 3.8-5.1 5.5-7.8h-.5c1.4.7 2.5 1.7 3.4 3 .2.3.8 0 .6-.4a8.4 8.4 0 00-3.7-3.1.4.4 0 00-.5.1 92 92 0 01-5.3 7.7h.5a145.7 145.7 0 01-9.4-4.5l-4 4.3-1.9 2.2c-.3.3-.6.5-1 .2l-.8-1.2-1.5-2.5c-.3-.6-.8-1.1-1.4-1.5a1.3 1.3 0 00-1.6.6l-1.5 2.4-3.6 5.7c-.2.4.4.7.6.4zM60.7 123.7l6.7-7.3h-.5a29 29 0 004.4 4.2c.6.5 1.4 1.3 2.2.7l.6-1a19.8 19.8 0 012.7-3.8 3 3 0 011.1-1c.6-.1 1 .3 1.5.6l2.7 2.1 1.4 1c.3.4.8.7 1.3.9a1.1 1.1 0 001-.3l1.4-1.3 2.8-2.7c.3-.4-.2-.9-.5-.5l-2.3 2.2-1.2 1.1-.5.6c-.2.1-.4.3-.7.1l-1.2-.9-1.3-1-2.6-2c-.7-.5-1.4-.8-2.2-.4-.8.4-1.5 1-2 1.8l-1.6 2.4-.6 1.2-.3.4-.8-.3a29 29 0 01-5-4.7.4.4 0 00-.3 0l-6.7 7.4c-.3.3.2.8.5.5zM68 171.6c.7-1.5 1.4-3.2 2.7-4.1.7-.5 1.4-.8 2.2-.8.8 0 1.5.3 2 .8.9.8 1.7 2 3 2.1a2.6 2.6 0 001.8-.7c.8-.6 1.4-1.3 2-2l5-4.6h-.4l4.5 2.6c.7.4 1.5 1 2.3 1.3a1.6 1.6 0 001.8 0l1.4-1.9 1.6-2.1 3.3-4.2h-.5l3.5 2.2 1.8 1.1c.5.4 1 .7 1.6.8a1.7 1.7 0 001.2-.8l1.3-1.6 2.8-3.2c.3-.4-.2-.9-.5-.5l-2.5 2.9-1.2 1.4c-.2.3-.7 1.1-1.2 1-.3 0-.6-.2-.8-.4l-.7-.4-1.7-1-3.2-2a.4.4 0 00-.5.1l-5.9 7.7c-.2.3-.5.5-.9.4a4 4 0 01-1-.4l-2-1.2-4.2-2.4a.4.4 0 00-.4 0l-4.4 4.3-2.1 2c-.6.6-1.3 1.2-2.2.9-.6-.3-1-.7-1.5-1.2-.4-.5-.9-1-1.4-1.3a4 4 0 00-4.2.4c-1.5 1-2.3 2.8-3 4.5-.2.4.4.7.6.3zM80.7 95.5c-2 1-4.2 1.4-6.4 1.5-.4 0-.5.6-.1.7 2.2.3 4.5 0 6.5-1.1.3-.2.2-.7-.2-.7-1.3.2-2.6.6-3.8 1-1.2.6-2.6.6-3.9.3l-.1.7A17.2 17.2 0 0082 97a.4.4 0 00-.4-.6A41.4 41.4 0 0171.3 98a.4.4 0 00.2.6 13.6 13.6 0 0010.9-1.7.4.4 0 000-.5l-.6-.6c-.3-.4-.8.1-.5.5l.6.6v-.6a12.9 12.9 0 01-10.2 1.6l-.1.7c3.4 0 6.9-.4 10.2-1.3l-.2-.7c-2.8 1-5.8 1.2-8.8.7a.4.4 0 10-.2.7c2.7 1 5.3-1 7.9-1.3l-.2-.6a10 10 0 01-6 1v.7c2.3 0 4.6-.6 6.7-1.6.4-.1 0-.8-.3-.6z" fill="#fff" />
        <path d="M66.3 97a15.6 15.6 0 0013.9.5c.4-.2 0-.8-.4-.6a14.8 14.8 0 01-13.1-.5c-.4-.2-.8.4-.4.7z" fill="#fff" />
        <path d="M131 86.2a17.6 17.6 0 0013.7-20.7 17.7 17.7 0 00-20-14.8 17.6 17.6 0 00-13.8 20.7 17.7 17.7 0 0020 14.8z" fill="#FFFBC7" />
        <path d="M129.7 84.8a16.7 16.7 0 0013.1-19.6 16.8 16.8 0 00-19-14 16.7 16.7 0 00-13.1 19.6 16.8 16.8 0 0019 14z" fill="#24286B" />
        <path d="M126.5 67.5c2.3 0 4.1-1.9 4.1-4.3s-1.8-4.3-4.1-4.3-4.2 2-4.2 4.3c0 2.4 1.9 4.3 4.2 4.3zM119.6 77.5a8.6 8.6 0 017.4-9c3.7-.5 8.4 2.2 8.7 6.9" fill="#E8F7FF" />
        <path d="M138.4 130.8c8.5 0 15.4-7.2 15.4-16s-7-16-15.4-16c-8.6 0-15.5 7.2-15.5 16s7 16 15.5 16z" fill="#D8DBF5" />
        <path opacity=".2" d="M137.2 129.3c8.3 0 15.1-7 15.1-15.7 0-8.6-6.8-15.6-15.1-15.6-8.4 0-15.1 7-15.1 15.6 0 8.7 6.7 15.7 15 15.7z" fill="#7AFFA6" stroke="#24286B" strokeWidth=".7" strokeMiterlimit="10" />
        <path d="M137.2 129.3c8.3 0 15.1-7 15.1-15.7 0-8.6-6.8-15.6-15.1-15.6-8.4 0-15.1 7-15.1 15.6 0 8.7 6.7 15.7 15 15.7z" stroke="#24286B" strokeWidth=".7" strokeMiterlimit="10" />
        <path d="M128.2 95.7s-5.9 1.6-7 7.1" stroke="#002C42" strokeWidth=".7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M134.1 126.9l.8-1 .2-.5.4-2.8v-.5l-.5-2.1-.1-.3-1.2-2v-.3l-.7-2.5V112.7c0-.4 0-2.3.5-2.8.8-.3 1.5-.5 2.3-.5h.5l1.6.6h.2l2.8.4.6.3.3.5.5 1.8V115.6l-.4 2.2v.2l-.8 1.7-.4 1c-.1.1-.2.3-.1.5l.2 3.4v.4l.7 1.8a1.1 1.1 0 01-.3 1.2 1 1 0 01-.4.2l-1.3.4H135a1 1 0 01-.8-.3 1.1 1.1 0 01-.3-1l.2-.4z" fill="#fff" />
        <path d="M135.5 123.4c-1.3-2.2-6.1-.6-7.4-1.8-.7-.5-1.5-.3-2-1.3-.6-1-2-1-1.2-2.8a9 9 0 012.2-2.7c4 0 .9-6.2 3-7.7 1.5-2 5.3-.6 6.6-1.6.4-.5 1-.8 1.5-1 .6-.2 1.2-.2 1.8-.1 2.5.3 3 1.4 5 2 1 .2 1.7.2 1.8.8.1.6.6 1.1 0 1.7-.6.5-1.4.6-1.4 1.5-2 3.6 4 5.4 2.7 8.9-.3 1 1 1.5-.6 2.3-3.3 2.6-4 2.3-7.2 1.9 0-1.3.2-2.6.5-3.8.8-.6 1.3-3.2 1.5-4.2-.4-1.6-.6-3.3-.6-5-1.8-.4-6.2-1.6-8-.4-.6 2-.2 4.1-.4 6a55 55 0 001.8 3.8l.4 3.5z" fill="#002C42" />
        <path d="M132.7 112.7c-.3 2.8.4 6 2.5 7.8 1.1 1 2.7 1.8 4.2 1.3s2.3-2.2 2.8-3.6c.6-1.9.7-3.9.2-5.8-.1-.5-.8-.3-.7.2a10 10 0 010 4.6c-.4 1.3-1 3-2.2 3.8-1.1.6-2.8 0-3.8-1a7.2 7.2 0 01-1.9-3 10 10 0 01-.4-4.3c0-.5-.7-.5-.7 0z" fill="#002C42" />
        <path d="M136.9 112.3l-.7 2.1-.2 1.6c0 .3 0 .5.2.7.2.2.4.4.6.4l.9-.1.6-.6a.4.4 0 000-.6h-.5c-.2.2-.5.7-.9.6a.3.3 0 01-.1-.3v-.5l.3-1.3.5-1.8v-.3l-.2-.2h-.3l-.2.3z" fill="#002C42" />
        <path d="M135.1 119.5c-1.1-.6-2.3 0-3.4.4a4 4 0 01-1.8.4c-.7 0-1-.6-1.4-1-.8-.8-2.4-.8-2.3-2.1.2-1.4 1.5-2 2.4-2.8.9-.7 1.6-1.8 1.3-3a4 4 0 01-.3-1.8c0-.7.4-1.3.9-1.8a5.2 5.2 0 014.9-1.4c.5.1 1 .2 1.4-.3l.5-.7.8-.5c.6-.2 1.3-.2 2-.1 1.4.2 2.8.9 4 1.8.4.4 1-.2.5-.5-1-1-2.3-1.6-3.7-1.9a5.2 5.2 0 00-3.5.2c-.2 0-.4.2-.6.4l-.6.7c-.2.3-.6.1-1 0h-1a6 6 0 00-4.8 2.3c-.3.6-.6 1.2-.7 1.8 0 .7.2 1.4.4 2a2.3 2.3 0 01-.8 2c-1 .8-2.2 1.4-2.7 2.7a2.1 2.1 0 00.4 2.3l.7.5 1 .4.7.9a2.3 2.3 0 001.8.6c.7 0 1.4-.3 2.1-.6a2.9 2.9 0 012.4-.3c.5.3.8-.4.4-.6z" fill="#002C42" />
        <path d="M135.9 109.6c3 1.5 6.4 1.7 9.6.5 1.1-.4 2.5-1.4 1.8-2.8a2.5 2.5 0 00-1.7-1.2l-2.6-.4c-.5 0-.5.7 0 .8l2.1.3c.7.1 1.4.3 1.6 1 .4 1.3-1.9 1.8-2.7 2-2.6.6-5.3.3-7.7-.8-.5-.2-.8.5-.4.7z" fill="#002C42" />
        <path d="M144.6 110.6a6 6 0 00.9 4l1.3 1.4.7.8c.1.3.2.7.1 1 0 .8-.4 1.5-.8 2.1-.5.5-1.3.4-1.9.4-.7-.1-1.3 0-2 .2-.5.2-1.4.7-1.9.1-.3-.4-.8.2-.5.6a1.5 1.5 0 001.3.5l1.8-.7c1.1-.2 2.3.4 3.3-.2.5-.4.8-.8 1-1.4.3-.6.5-1.2.5-2 0-1.2-1-1.9-1.8-2.7a5 5 0 01-1.3-4c.1-.6-.6-.6-.7 0zM135 120.8c.4 2 0 4.2-1 6-.3.3.3.8.5.5l.6-.6c.3-.4-.3-1-.6-.6l-.5.6.6.5c1.2-2 1.6-4.4 1.1-6.6 0-.5-.8-.3-.7.2z" fill="#002C42" />
        <path d="M140.2 120.6a8 8 0 00.5 5.3c.2.4.8 0 .6-.4a7.2 7.2 0 01-.4-4.7v-.3l-.2-.2h-.3l-.2.3z" fill="#002C42" />
        <path d="M171 99.4a15 15 0 0014.8-15.2A15 15 0 00171 69a15 15 0 00-14.7 15.2A15 15 0 00171 99.4z" fill="#BEDEEF" />
        <path d="M169.7 98.4a15 15 0 0014.7-15.2A15 15 0 00169.7 68 15 15 0 00155 83.2a15 15 0 0014.7 15.2z" fill="#F9D4D5" stroke="#24286B" strokeWidth=".7" strokeMiterlimit="10" />
        <path d="M155.5 71.3s-5.3 4.5-3.9 10.8" stroke="#002C42" strokeWidth=".7" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M173.6 97.3l-.8-2.5.2-3.2 1.8-.4 1.4-.8.9-3.3-.3-2-.9-2.9-.4-1s-4.7-5.2-4.6-5.8c.2-.7-7.6 3.5-7.6 3.5l-2.3 5.7-.8 1.5.2.9 1.2.8 1.9.5 2 3.2.6 5.2v.6l4.3.4h1l2.2-.4z" fill="#fff" />
        <path d="M163.8 83.9c-.5.4-1.2.7-1.8.7-.6 0-1.2.1-1.7.4-.3.3-.5.7-.5 1 0 .5 0 .9.2 1.2.2.5.6.9 1 1 .5.3 1 .3 1.5.2.7 0 1.3-.3 1.9-.5a1.1 1.1 0 01.9-.2c.2.2.5.5.6.8a8.3 8.3 0 005.3 3.5c1 .2 2.1.1 3.1-.1a3.4 3.4 0 002.5-2.2 10 10 0 00-.8-8.3l-.3-.2h-.3l-.2.3v.3c1.2 2 1.6 4.4 1.1 6.6-.2 1.2-.7 2.2-2 2.6a7.3 7.3 0 01-7.6-2.8c-.4-.7-1-1.5-1.9-1.4-1.1.2-2 1.1-3.3.7-.7-.2-1.3-1.7-.2-2 .6 0 1.2 0 1.7-.2.6-.1 1-.4 1.5-.8.4-.4-.2-1-.6-.6z" fill="#002C42" />
        <path d="M164.5 87.3c.6 1.2.9 2.6.9 4-.2 1-1.6.9-2.2.5-.9-.5-1-2.8-.9-3.8" fill="#002C42" />
        <path d="M164.1 87.5a8.3 8.3 0 01.9 3.6c0 .2 0 0 0 0 0 .2 0 .3-.2.3 0 .1.1 0 0 0l-.2.1a1 1 0 01-.3 0H163.6l-.2-.1v-.1l-.2-.3a4 4 0 01-.3-.9 7 7 0 010-2c0-.3-.3-.5-.5-.5l-.3.1-.1.3a7.7 7.7 0 00.4 3.4l.7.8a2.3 2.3 0 002.1 0c.9-.4.7-1.4.6-2.2 0-1-.4-2-.8-3-.3-.4-1 0-.7.5h-.2zM160.6 85.3c-.9-1.6-.7-5.3-.1-7.1a7 7 0 013.6-4.1l-.5 1.4c2.1-1.6 4.3-3.2 6.9-3.4 2.9-.3 5.9 1.3 8.6.4a2 2 0 01-.6 2c-.5.5-1.1.8-1.8 1l-4.2 2 .5.4c-1.3 1.4-3.6 0-5.3.8-.6.3-1.1.8-1.5 1.3-.7.6-1.1 1.4-1.4 2.3l-.1 1.2c-.1.4.3 1.4 0 1.4" fill="#002C42" />
        <path d="M161 85.1c-.4-.8-.6-1.7-.6-2.7 0-1 0-2 .2-3a6.8 6.8 0 013.7-5l-.6-.5-.5 1.5c0 .3.4.7.7.5 2.1-1.7 4.5-3.4 7.3-3.4s5.3 1.2 8 .4l-.5-.3c.4 1.4-1.3 2.2-2.3 2.6l-4.1 1.8-.2.1a.4.4 0 000 .4v.1c.2.3.4.5.7.6l-.1-.7c-.8.8-2 .5-3 .5a4 4 0 00-3 .8c-1 .8-1.7 1.7-2.2 2.9a7.8 7.8 0 00-.2 2.7v.1c0 .2.1.2.3-.1-.5 0-.6 1 0 .9a.6.6 0 00.4-.3.6.6 0 00.2-.4 7.3 7.3 0 010-2.5l.5-1 1.3-1.5c1-1 2.1-.8 3.4-.7a3.4 3.4 0 002.8-.8.4.4 0 00.1-.3.4.4 0 00-.1-.3.8.8 0 01-.4-.3l-.1.6 5-2.2c1.1-.6 2.2-1.8 1.8-3.3l-.2-.2h-.3c-2.8.8-5.6-.7-8.4-.5-2.8.2-5 1.9-7.2 3.5l.6.5.5-1.5c.1-.4-.3-.6-.6-.5a7.6 7.6 0 00-4 5.5c-.4 2-.6 4.6.4 6.4.2.5 1 0 .7-.4zM165 90.4l.7 3.3v3l.1.4.3.1.3-.1.1-.3c.2-1.1.2-2.2 0-3.3a27 27 0 00-1-3.6h-.3s-.2 0-.2.2v.3zM173 91.3a3.1 3.1 0 00-.7 2.5c0 .9.3 1.8.3 2.6l.1.4a.4.4 0 00.6 0l.1-.4-.3-2.4a2.5 2.5 0 01.6-2c.3-.5-.3-1.1-.6-.7zM169 86.6a4.7 4.7 0 005-.5.4.4 0 000-.3.4.4 0 000-.3.4.4 0 00-.4 0h-.1a3.9 3.9 0 01-4.1.3c-.5-.3-1 .5-.4.8z" fill="#002C42" />
        <path d="M170.7 80.4c-.1 1 .8 1.7 1.6 2.2l.5.5v.1c.1 0 0 0 0 0l.1.1s0 .1 0 0c0 0 0 .1 0 0 0 0 0 .1 0 0 0 .2-.3.3-.4.4-.5.3 0 1 .4.7.5-.2.8-.5.9-1a1.4 1.4 0 00-.5-1.1c-.4-.4-.9-.6-1.2-1l-.5-.5a.7.7 0 01-.1-.5l-.1-.3-.3-.1-.3.1-.2.3v.1z" fill="#002C42" />
      </g>
      <defs><clipPath id="clip0_970_6070"><path fill="#fff" transform="translate(19.5 29)" d="M0 0h166.3v156.2H0z" /></clipPath></defs>
    </svg>
  );
};
