import type { ReactNode } from 'react';
import { Children } from 'react';
import { Link } from '@/navigation';
import { ProjectItemContextEnum } from '~/components/Homepage/ProjectsScroller/model';
import { AllProjectItemSkeleton } from '~/components/Homepage/AllProjects/skeletons/skeletons';
import { MainColumn } from '~/components/commons/MainColumn/MainColumn';
import { ProjectsListItem } from '~/components/ProjectsListItem/ProjectsListItem';
import type { AnalyticEvents } from '~/const/appConst';
import appConst from '~/const/appConst';
import type { ProjectType } from '~/model/ProjectType';
import { dataLayerPush } from '~/utils/analytics/dataLayerPush';
import type { ProjectTagType } from '~/model/ProjectTag';

export const ProjectsList = ({
  buttonLabel,
  isLoading,
  maxItems = 4,
  projects,
  title,
  projectItemContext = ProjectItemContextEnum.homepageAll,
  analyticsEvent = null,
  tags = [],
}: {
  buttonLabel: ReactNode;
  isLoading: boolean;
  maxItems?: number;
  projects: ProjectType[];
  title: ReactNode;
  projectItemContext?: ProjectItemContextEnum;
  analyticsEvent?: AnalyticEvents;
  tags?: ProjectTagType[];
}) => {
  return (
    <section className="all-projects">
      <MainColumn>
        <h2 className="vp-title-m">{title}</h2>
        <div className="all-projects__inner">
          <ul className="projects-list">
            {isLoading || !projects
              ? Children.toArray(
                [...Array(maxItems).keys()].map(() => (
                  <AllProjectItemSkeleton />
                )),
              )
              : projects.map(
                project => (
                  <li key={project['@id']}>
                    <ProjectsListItem
                      project={project}
                      instanceName={projectItemContext}
                      imageSizes="(max-width: 768px) 50vw, (max-width: 992px) 33vw, 20vw"
                      tags={tags}
                    />
                  </li>
                ),
              )}
          </ul>
          <Link
            className="vp-button vp-button--squared button-wrapper-link"
            href={appConst.staticRoutes.projects}
            prefetch={false}
            onClick={() => {
              analyticsEvent && dataLayerPush({
                event: analyticsEvent,
              });
            }}
          >
            <span className="vp-button__label" data-part="label">
              {buttonLabel}
            </span>
          </Link>
        </div>
      </MainColumn>
    </section>
  );
};
