import { isAfter } from 'date-fns';
import type { MessageKeys } from 'next-intl';
import type { ProjectTagType } from '~/model/ProjectTag';
import type { ProjectType } from '~/model/ProjectType';
import { InteractionTags } from '~/model/InteractionType';
import type { Messages } from '@/global';

export const checkIfProjectIsFinished = (project: ProjectType) => {
  const now = Date.now();
  return isAfter(now, new Date(project?.end_at));
};

// Return a list of active projects ids
// of which the matching tag has not already been fetched
export const getActiveProjectsIds = (
  projects: ProjectType[] = [],
  tags: ProjectTagType[] = [],
) =>
  projects
    // filter active projects
    ?.filter(p => !checkIfProjectIsFinished(p))
    // convert to id list
    .map(p => p.id)
    // and filter to exclude those already present in Redux (projectTags selector)
    .filter((id) => {
      // If tags are null, do not filter
      if (!tags)
        return true;
      // else, omit ids already present among tags
      return !tags.map(t => t.project_id).includes(id);
    }) || [];

export const getProjectTagLabel = (projectTag: ProjectTagType) => {
  const interactionTag = projectTag?.interaction_tag;
  return (Object.values(InteractionTags).includes(
    projectTag?.interaction_tag,
  )
    ? `project.status.new_${interactionTag.toLowerCase()}`
    : '') as MessageKeys<
    Messages,
    | 'project.status.new_discussion'
    | 'project.status.new_event'
    | 'project.status.new_interview'
    | 'project.status.new_news'
    | 'project.status.new_product_test_onsite'
    | 'project.status.new_product_test_remote'
    | 'project.status.new_result'
    | 'project.status.new_roundtable'
    | 'project.status.new_survey_feedback'
    | 'project.status.new_survey_quantitative'
    | 'project.status.new_user_test'
    | 'project.status.new_workshop'
  >;
};
